import PerfectScrollbar from 'perfect-scrollbar';
import tippy, { hideAll } from 'tippy.js';

var windowWidth = 0;
var listHeight = 0;

var psLeft, psRight;

$(window).resize(x13debounce(function () {
    windowWidth = $(window).width();
    listHeight = $(window).height() - 150 > 600 ? 600 : $(window).height() - 150;
    $('.x13lookbook__product-list').css('max-height', `${listHeight}px`);
    $('.x13lookbook__left-column').css('max-height', `${listHeight}px`);
    if (typeof psLeft == 'object') {
        psLeft.update();
    };
    if (typeof psRight == 'object') {
        psRight.update();
    };

}, 100))

$(document).ready(function () {
    windowWidth = $(window).width();
    listHeight = $(window).height() - 150 > 600 ? 600 : $(window).height() - 150;
    if ($('.x13lookbook__product-list').length > 0 && $('.x13lookbook__left-column').length > 0) {
        $('.x13lookbook__product-list').css('max-height', `${listHeight}px`);
        $('.x13lookbook__left-column').css('max-height', `${listHeight}px`);
    }
    x13lookbookInitTippy();
    x13lookbookDotHover();
    x13lookbookDataAction();

    $('.x13lookbook__dot').fancybox({
        'width': 900,
        'height': 'auto',
        'autoSize': false,
        'closeExisting': true,
        'afterShow': function () {
            let index = $(this)[0].element.data('dot_index');

            psLeft = new PerfectScrollbar('.fancybox-overlay .x13lookbook__left-column', { wheelPropagation: false });
            if ($('.fancybox-overlay .x13lookbook__product-list').length > 0) {
                psRight = new PerfectScrollbar('.fancybox-overlay .x13lookbook__product-list', { wheelPropagation: false });
            }

            $(`.fancybox-overlay .x13lookbook__product-list`).x13lookbookScrollTo($(`li[data-index="${index}"]`), 0)

        }
    });
})

function x13debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function x13lookbookInitTippy() {
    tippy('.x13lookbook__tippy', {
        theme: 'light',
        allowHTML: true,
        interactive: true,
        flipOnUpdate: true,
        hideOnClick: false,
        trigger: 'mouseenter',
        appendTo: () => document.body,
        onShow: ({ reference }) => {
            hideAll({ exclude: reference });
            let $ref = $(reference);
            let $parent = $ref.closest('.x13lookbook__image');

            $parent.addClass('tippy-hovered');
        },
        onHide: ({ reference }) => {
            let $ref = $(reference);
            let $parent = $ref.closest('.x13lookbook__image');

            $parent.removeClass('tippy-hovered');
        },
    })
}

function x13lookbookUpdateProductList(index) {
    $('.x13lookbook__product-list').find('li').removeClass('active');
    $('.x13lookbook__product-list').find(`li[data-index="${index}"]`).addClass('active');
}

function x13lookbookClearModalContent() {
    $('.x13lookbook__product-title').html('');
    $('.x13lookbook__product-details').html('');
    $('.x13lookbook__product-image').html('');
    $('.x13lookbook__add-to-cart').html('');
    $('.x13lookbook__product-description').html('');
}

function x13lookbookFillDataProduct(product, omnibus, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').text(product.name);

    let html = '';
    if (product.reference_to_display) {
        html += `<p class="x13lookbook__product-reference"><strong>${x13lookbookIndex}:</strong> ${product.reference_to_display}</p>`
    }

    if (settings.item_price != '0') {
        if (product.show_price && product.has_discount) {
            html += `<p class="x13lookbook__product-prices"><strong>${x13lookbookPrice}:</strong> ${product.price} <span>${product.regular_price}</span></p>`;

            if (typeof omnibus === 'string' && omnibus !== '') {
                html += omnibus;
            }
        }
        else if (product.show_price) {
            html += `<p class="x13lookbook__product-prices"><strong>${x13lookbookPrice}:</strong> ${product.price}</p>`;
        }
    }

    if (settings.item_qty != '0') {
        if (!(!product.add_to_cart_url || (product.quantity < product.minimal_quantity && !product.allow_oosp))) {
            html += `<p class="x13lookbook__product-quantity"><strong>${x13lookbookInStock}:</strong> ${product.quantity}</p>`;
        }
    }

    if (product.attachments.length > 0) {
        html += `<p class="x13lookbook__product-attachments"><strong>${x13lookbookAttachments}:</strong> ${product.attachments.length}</p>`;
    }

    $('.x13lookbook__product-details').html(`${html}`);
    if (settings.item_cover != '0' && product.cover != '') {
        $('.x13lookbook__product-image').html(`<img src="${product.cover}" alt="${product.name}" />`);
    }

    let description = '';
    if (settings.item_shortdescription != '0') {
        description = `${product.description}`;
    }
    let features = '';
    if (settings.item_features != '0') {
        features = '<table class="table table-striped">';
        for (let feature of product.features) {
            features += `<tr><td>${feature.name}</td><td>${feature.value}</td></tr>`;
        }
        features += '</table>';
    }
    $('.x13lookbook__product-description').html(`${description} ${features} `);


    let cartHtml = `<div class="btn-wrapper">`;
    if (settings.item_add_to_cart_button != '0') {
        let addToCartText = x13lookbookAddToCart;

        if (settings.item_view_button != '0') {
            addToCartText = x13lookbookToCart;
        }

        if (!product.add_to_cart_url || (product.quantity < product.minimal_quantity && !product.allow_oosp)) {
            cartHtml += `<span class="btn btn-primary btn-block disabled" href="${product.add_to_cart_url}" title="${x13lookbookAddToCart}" rel="nofollow">${addToCartText}</span>`;
        } else {
            cartHtml += `<a class="btn btn-primary btn-block" href="${product.add_to_cart_url}" title="${x13lookbookAddToCart}" rel="nofollow">${addToCartText}</a>`;
        }
    }

    if (settings.item_view_button != '0') {
        cartHtml += `<a class="btn btn-default btn-block" href="${product.link}" title="${x13lookbookViewProduct}" rel="nofollow">${x13lookbookView}</a>`;
    }
    cartHtml += `</div>`;

    if (product.attachments.length == 1) {
        cartHtml += `<a class="btn btn-danger btn-block" href="${product.attachments[0].link}" title="${x13lookbookDownloadFile}" rel="nofollow"> ${x13lookbookDownloadFile}: ${product.attachments[0].name}</a>`;
    } else if (product.attachments.length > 1) {
        cartHtml += `
          <p>
          <a class="btn btn-danger btn-block" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">${x13lookbookShowFiles}</a>
          </p> <div class="collapse" id="collapseExample"><div class="card card-body x13attachments">`;
        for (let i = 0; i < product.attachments.length; i++) {
            cartHtml += `<a href="${product.attachments[i].link}" title="${x13lookbookDownloadFile}">${product.attachments[i].name}</a><br/>`;
        }
        cartHtml += `</div></div>`;
    }

    $('.x13lookbook__add-to-cart').html(cartHtml);

    x13lookbookUpdateProductList(product.dot_index);
}

$.fn.x13lookbookScrollTo = function (elem, speed) {
    if ($(this).length > 0) {
        $(this).animate({
            scrollTop: $(this).scrollTop() - $(this).offset().top + $(elem).offset().top
        }, speed == undefined ? 1000 : speed);
        return this;
    }
};

function x13lookbookFillDataCustom(text, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').text(text.title);
    $('.x13lookbook__product-details').html(text.description);
    x13lookbookUpdateProductList(text.dot_index);
}

function x13lookbookFillDataCms(text, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    $('.x13lookbook__product-details').html(text.description);
    x13lookbookUpdateProductList(text.dot_index);
}

function x13lookbookFillDataManufacturer(text, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    if (settings.item_cover != '0' && text.cover != '') {
        $('.x13lookbook__product-image').html(`<img src="${text.cover}" alt="${text.name}" />`);
    }
    $('.x13lookbook__product-details').html(text.description);
    x13lookbookUpdateProductList(text.dot_index);
}

function x13lookbookFillDataCategory(text, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').html('<a href="' + text.link + '" title="' + text.name + '">' + text.name + '</a>');
    if (settings.item_cover != '0' && text.cover != '') {
        $('.x13lookbook__product-image').html(`<img src="${text.cover}" alt="${text.name}" />`);
    }
    x13lookbookUpdateProductList(text.dot_index);
}

function x13lookbookFillDataImage(text, settings) {
    x13lookbookClearModalContent();
    $('.x13lookbook__product-title').text(text.title);
    $('.x13lookbook__product-details').html(text.description);
    if (settings.item_cover != '0' && text.image != '') {
        $('.x13lookbook__product-image').html(`<img src="${text.image}" alt="${text.title}" />`);
    }
    x13lookbookUpdateProductList(text.dot_index);
}

function x13lookbookDataAction() {
    $(document).on('click', 'li[data-action], .x13lookbook__dot[data-action]', function (e) {
        let $this = $(this);
        let $action = $this.data('action');
        let item = $this.data($action) === undefined ? $this.closest('li').data($action) : $this.data($action);
        let omnibus = $this.data('omnibus') === undefined ? $this.closest('li').data('omnibus') : $this.data('omnibus');
        item = JSON.parse(JSON.stringify(item).replace(/&quot;/g, '\\"'));
        let settings = $(`.x13lookbook[data-schema=${item.id_schema}]`).data('settings');

        switch ($action) {
            case 'custom':
                x13lookbookFillDataCustom(item, settings);
                break;
            case 'cms':
                x13lookbookFillDataCms(item, settings);
                break;
            case 'manufacturer':
                x13lookbookFillDataManufacturer(item, settings);
                break;
            case 'category':
                x13lookbookFillDataCategory(item, settings);
                break;
            case 'image':
                x13lookbookFillDataImage(item, settings);
                break;
            case 'product':
                x13lookbookFillDataProduct(item, omnibus, settings);
                break;
            default:
                break;
        }

        if ($('.x13lookbook__product-title').html() != '') {
            $('.x13lookbook__product-title').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-title').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }

        if ($('.x13lookbook__product-image').html() != ''
            || $('.x13lookbook__product-details').html() != '') {
            $('.x13lookbook__product-image').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-image').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }

        if ($('.x13lookbook__product-description').html() != ''
            || $('.x13lookbook__add-to-cart').html() != '') {
            $('.x13lookbook__product-description').closest('.row').css({
                'margin-top': '',
                'margin-bottom': ''
            });
        } else {
            $('.x13lookbook__product-description').closest('.row').css({
                'margin-top': '0',
                'margin-bottom': '0'
            });
        }

    });
}

function x13lookbookDotHover() {
    $('.x13lookbook__dot').on('mouseenter', function (e) {
        let siblings = $(e.target).siblings('a');
        $(e.target).addClass('x13hide');
        siblings.addClass('x13opacity x13hide');
    }).on('mouseleave', function (e) {
        let siblings = $(e.target).siblings('a');
        $(e.target).removeClass('x13hide');
        siblings.removeClass('x13opacity x13hide');
    })
}
